import './components/HomePage/Homepage' ;
import '@fortawesome/fontawesome-free/css/all.min.css';
import Homepage from './components/HomePage/Homepage';
import './App.css';
import Navigation from './components/Navigation/Navigation';

  
export default function App() {
  return (
  <div className="relative">
  <div className="area">
    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  <Navigation />
  <Homepage />
</div>

  
  );
}

