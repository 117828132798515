import React from 'react'

function About() {
  return (
    <div id='About' className='flex flex-col items-center justify-center px-4 sm:px-8 -mt-16 mb-8  '>
    <h1 className="text-3xl font-bold md:text-4xl mb-6  font-sans">
      <span className='text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500 animate-[wiggle_1s_ease-in-out_infinite]'>
      ABOUT ME
      </span>
    </h1>



    <div class="w-full p-4 text-center font-thin text-lg  text-white  sm:p-8 dark:bg-gray-800 dark:border-gray-700">
    <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
    Hi, my name is Nidhi Shetty, and I am a Front-End Developer with 3 years of experience creating intuitive and engaging user interfaces. 
      My expertise lies in building responsive, user-friendly web applications that merge creativity with functionality. 
      Each project has been an opportunity to refine my skills, embrace challenges, and deliver visually appealing solutions that enhance user experiences.
      Welcome to my portfolio, where design meets development to create innovative and impactful results.

      
      In addition to my front-end expertise, I have a background in software engineering, customer service, and event coordination. 
      As a Software Engineer at Reliance Jio Platforms Ltd., I specialized in optimizing system efficiency, building administrative websites, and managing user data using technologies like Angular, TypeScript, Android, Java, and SQL. 
      I also gained valuable customer-facing experience working at Zehrs, where I improved inventory management, handled POS systems, and resolved software issues related to transaction software. 
      This experience helped me sharpen my problem-solving and technical troubleshooting skills, ensuring smooth operations and enhancing the customer experience.

      I hold a Postgraduate Degree in Web Development from Conestoga College and a Bachelor’s Degree in Computer Engineering from the University of Mumbai. 
      I’ve earned certifications in Microsoft Certified: Azure AI Fundamentals and Amazon Web Services (AWS) Foundation: Getting Started with the AWS Cloud Essentials.
      My education and certifications, combined with my professional experience, have equipped me with a solid foundation in software engineering and web management. 
      I’ve also contributed as a Spring Orientation Volunteer at Conestoga College, enhancing event efficiency and participant satisfaction. 
      I am passionate about leveraging my technical and interpersonal skills to create impactful solutions and contribute to innovative teams.   

    </div>
</div>


</div>

  )
}

export default About