import React from "react";
import {
  SiAngular,
  SiReact,
  SiJavascript,
  SiHtml5,
  SiTypescript,
  SiCss3,
  SiBootstrap,
  SiMongodb,
  SiAndroid,
  SiMysql,
  SiGit,
} from "react-icons/si";
import { FaJava } from "react-icons/fa6";

function Skills() {
  const skillLevels = [
    { icon: <SiAngular size={48} color="#DD0031" />, name: "Angular" },
    { icon: <SiReact size={48} color="#61DBFB" />, name: "React" },
    { icon: <SiJavascript size={48} color="#61DBFB" />, name: "JavaScript" },
    { icon: <SiTypescript size={48} color="#3178C6" />, name: "TypeScript" },
    { icon: <FaJava size={48} color="#007396" />, name: "Java" },
    { icon: <SiHtml5 size={48} color="#E34F26" />, name: "HTML" },
    { icon: <SiCss3 size={48} color="#1572B6" />, name: "CSS" },
    { icon: <SiBootstrap size={48} color="#7952B3" />, name: "Bootstrap" },
    { icon: <SiMongodb size={48} color="#4DB33D" />, name: "MongoDB" },
    { icon: <SiAndroid size={48} color="#3DDC84" />, name: "Android" },
    { icon: <SiMysql size={48} color="#4479A1" />, name: "MySQL" },
    { icon: <SiGit size={48} color="#F05032" />, name: "Git" },
  ];

  return (
    <div className="flex flex-wrap justify-center gap-10 mt-20">
      {skillLevels.map((skill, index) => (
        <div
          key={index}
          className="sm:mt-2 perspective-[1000px] hover:scale-105 transform transition-all"
        >
          <article className="transform hover:rotateX-12 hover:rotateY-12 scale-100 transition-all duration-300">
            <div
              className="rounded-full flex justify-center items-center  text-white shadow-lg hover:shadow-xl border-2 border-blue-800 hover:bg-gradient-to-r from-blue-500  to-purple-950 p-4 transition-all duration-300"
              style={{ width: 100, height: 100 }}
            >
              {skill.icon}
            </div>
          </article>
        </div>
      ))}
    </div>
  );
}

export default Skills;
