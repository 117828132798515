import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/logo.png";
import { FaGithub } from "react-icons/fa";
import { AiOutlineLinkedin } from "react-icons/ai";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
      dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <nav className="border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center p-4 relative">
        {/* Logo */}
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img src={logo} className="w-16" alt="Logo" />
          </a>
        </div>

        {/* Hamburger Menu */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-[#8f94fb] rounded-lg md:hidden 
          hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 
          dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen ? "true" : "false"}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        {/* Icons  */}
        <div
          ref={menuRef}
          ref1={dropdownRef}
          className={`absolute right-0 top-full mt-2 w-52 bg-[#151030] rounded-lg shadow-lg border border-transparent dark:bg-gray-800 dark:border-gray-700 
          transform transition-all duration-300 ease-in-out p-4 ${
            isMenuOpen
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 -translate-y-2 pointer-events-none"
          } md:flex md:static md:bg-transparent md:shadow-none md:border-none md:p-0 md:w-auto md:opacity-100 md:scale-100 md:translate-y-0 md:pointer-events-auto`}
        >
          <div className="flex flex-col md:flex-row items-center md:space-x-8 space-y-4 md:space-y-0 " >

            {[
              {href:"tel:548-333-0997", icon: <CiMobile3  size={42} color="#FFFFFF"/>},
              {href:"https://github.com/Code2voyager" , icon: <FaGithub size={40} color="#FFFFFF"/>},
              {href:"https://www.linkedin.com/in/nidhis224/" , icon: <AiOutlineLinkedin size={40} color="#FFFFFF"/>},
              {href:"mailto:nidhi1709shetty@gmail.com" , icon: <MdOutlineMailOutline size={40} color="#FFFFFF"/>},
            ].map((item, index) => (
              <a href={item.href} target="_blank" rel="noopener noreferrer" key={index} >
              <div className="hover:animate-bounce " >            
                      {item.icon}
                      </div>
                 
            
                </a>
            ))}





            {/* // <a href="tel:548-333-0997" target="_blank" rel="noopener noreferrer" >
            //   <CiMobile3  size={42} color="#FFFFFF" className="hover:border-2 border-solid bg-gradient-to-r from from-cyan-500 to-blue-500" />
            // </a>
            // <a href="https://github.com/Code2voyager" target="_blank" rel="noopener noreferrer">
            //   <FaGithub size={40} color="#FFFFFF"/>
            // </a>
            // <a href="https://www.linkedin.com/in/nidhis224/" target="_blank" rel="noopener noreferrer">
            //   <AiOutlineLinkedin size={40} color="#FFFFFF" />
            // </a>
            // <a href="mailto:nidhi1709shetty@gmail.com" target="_blank" rel="noopener noreferrer">
            //   <MdOutlineMailOutline size={42} color="#FFFFFF" />
            // </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
